import React from "react";
import ReactDOM from "react-dom";
//import { createBrowserHistory } from "history";
//import { Router, Route, Switch } from "react-router-dom";
import "assets/scss/material-kit-react.scss?v=1.9.0";
import Routes from "./router"

// pages for this product
// import Components from "views/Components/Components.js";
// import LandingPage from "views/LandingPage/LandingPage.js";
// import ProfilePage from "views/ProfilePage/ProfilePage.js";
// import LoginPage from "views/LoginPage/LoginPage.js";

//var hist = createBrowserHistory();

ReactDOM.render(
  <Routes />,
  document.getElementById("root")
);
