import React, {useEffect} from "react";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";

var hist = createBrowserHistory();

function usePageViews() {
  let location = window.location;
  useEffect(()=>{
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize('G-92GGK7YW2W');
      window.GA_INITIALIZED = true;
    }
    ReactGA.set({page: location.pathname});
    ReactGA.pageview(window.location.pathname);
}, [location.href]);
}

export default function App() {
    usePageViews();

    return (<Router history={hist}>
        <Switch>
        <Route path="/landing-page" component={LandingPage} />
        <Route path="/profile-page" component={ProfilePage} />
        <Route path="/login-page" component={LoginPage} />
        <Route path="/" component={Components} />
        </Switch>
    </Router>);
}
