import React from "react";
import Card from "@material-ui/core/Card"
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import BackgroundCard from "../BackgroundCard.js"

import Favorite from "@material-ui/icons/ThumbUp";
import Upload from "@material-ui/icons/CloudUpload";
import School from "@material-ui/icons/School";
import Refresh from "@material-ui/icons/Refresh";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
import "../../../assets/css/aiBullet.css";

const useStyles = makeStyles(styles);

export default function SectionHowItWorks() {
  const classes = useStyles();


  return (
    <div id="howitworks" className={classes.sections}>
      <div className={classes.container}>
      {/* <Card className={classes.container} style={{padding: 20}}> */}
        <div>
          <h1 style={{fontFamily: 'Roboto', textAlign: 'center', marginBottom: '20'}}><strong>How It Works</strong></h1>
        </div>
          {/* <div className={classes.card}> */}
            {/* <ul className="aiBullet">  
                <li style={{fontSize: '1.1rem', fontFamily: 'Roboto'}}>To Drive more revenue with sales and lead development resources</li>
                <li style={{fontSize: '1.1rem', fontFamily: 'Roboto'}}>To Stop wasting time cold-calling and cold-emailing too many prospects</li>
                <li style={{fontSize: '1.1rem', fontFamily: 'Roboto'}}>To Build a Customized Sales Learning Engine</li>
            </ul> */}
            <GridContainer style={{marginBottom: '20px'}}>
                <GridItem xs={6} style={{fontSize: '1.1rem', fontFamily: 'Roboto', textAlign: 'center'}}>
                  <BackgroundCard 
                    num="1"
                    text="1.  Import, Categorize, and Enrich Initial Data"
                    icon={<Upload fontSize="large" style={{ color: '#000080' }}/>}
                  />
                </GridItem>
                <GridItem xs={6} style={{fontSize: '1.1rem', fontFamily: 'Roboto', textAlign: 'center'}}>
                  <BackgroundCard 
                    num="2"
                    text="2.  Apply Machine Learning"
                    icon={<School fontSize="large" style={{ color: '#000080' }} />}
                  />
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={6} style={{fontSize: '1.1rem', fontFamily: 'Roboto', textAlign: 'center'}}>
                  <BackgroundCard 
                    num="3"
                    text="3.  Categorize Recommendations"
                    icon={<Favorite fontSize="large" style={{ color: '#000080' }} />}
                  />
                </GridItem>
                <GridItem xs={6} style={{fontSize: '1.1rem', fontFamily: 'Roboto', textAlign: 'center'}}>
                  <BackgroundCard 
                    num="1"
                    text="4.  Reapply Machine Learning and Repeat"
                    icon={<Refresh fontSize="large" style={{ color: '#000080' }} />}
                  />
                </GridItem>
            </GridContainer>
          {/* </div> */}
        </div>
      {/* </Card> */}
    </div>
  );
}
