/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {Container, Row, Col} from 'reactstrap';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { //People, 
  AttachMoney, HelpOutlineRounded, Build,
  Home, Info, Phone as ContactPhone //PeopleAltRounded 
} from "@material-ui/icons";
//import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';

// core components
// import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
          {/* <ListItem className={classes.listItem}>
              <Button
                href="#top"
                color="transparent"
                className={classes.navLink}
                onClick={props.handleDrawerToggle}
              >{<Home className={classes.icons} />}
                Home
              </Button>
          </ListItem> */}
          <ListItem className={classes.listItem}>
            {/* <Tooltip
                id="pureblue-why"
                title="Why do I need PureBlue?"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              > */}
              <Button
                href="#whypureblue"
                color="transparent"
                className={classes.navLink}
                onClick={props.handleDrawerToggle}
              >{<HelpOutlineRounded className={classes.icons} />}
                <span style={{fontFamily: 'Roboto'}}>Why PureBlue?</span>
              </Button>
            {/* </Tooltip> */}
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              href="#howitworks"
              color="transparent"
              className={classes.navLink}
              onClick={props.handleDrawerToggle}
              >{<Build className={classes.icons} />}How It Works
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              href="#pricing"
              color="transparent"
              className={classes.navLink}
              onClick={props.handleDrawerToggle}
              >{<AttachMoney className={classes.icons} />}Pricing
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            {/* <Tooltip
              id="pureblue-contact"
              title="Contact"
              placement={window.innerWidth > 959 ? "top" : "left"}
              classes={{ tooltip: classes.tooltip }}
            > */}
            <Button
              href="#contact"
              color="transparent"
              className={classes.navLink}
              onClick={props.handleDrawerToggle}
              >{<ContactPhone className={classes.icons} />}Contact
            </Button>
            {/* </Tooltip> */}
          </ListItem>
          <ListItem className={classes.listItem} style={{marginRight: '5px'}}>
            {/* <Tooltip
              id="more-info"
              title="Get More Info"
              placement={window.innerWidth > 959 ? "top" : "left"}
              classes={{ tooltip: classes.tooltip }}
            > */}
              <Button
                color="transparent"
                href="#moreinfo"
                //target="_blank"
                className={classes.navLink}
                onClick={props.handleDrawerToggle}
              >{<Info className={classes.icons} />}
                More Info
              </Button>
            {/* </Tooltip> */}
          </ListItem>      
          <ListItem className={classes.listItem}>
            {/* <Tooltip
              id="instagram-twitter"
              title="Follow us on twitter"
              placement={window.innerWidth > 959 ? "top" : "left"}
              classes={{ tooltip: classes.tooltip }}
            > */}
              <Button
                href="https://twitter.com/pureblueai?ref=purebluewebsite"
                target="_blank"
                color="transparent"
                className={classes.navLink}
                onClick={props.handleDrawerToggle}
              >
                <i className={classes.socialIcons + " fab fa-twitter"} />
              </Button>
            {/* </Tooltip> */}
          </ListItem>

          <ListItem className={classes.listItem}>
            {/* <Tooltip
              id="linkedin-tooltip"
              title="Follow us on LinkedIn"
              placement={window.innerWidth > 959 ? "top" : "left"}
              classes={{ tooltip: classes.tooltip }}
            > */}
              <Button
                color="transparent"
                href="https://www.linkedin.com/company/ailead/"
                target="_blank"
                className={classes.navLink}
                onClick={props.handleDrawerToggle}
              >
                <i className={classes.socialIcons + " fab fa-linkedin"} />
              </Button>
            {/* </Tooltip> */}
          </ListItem>
          <ListItem className={classes.listItem + ' ' + classes.loginButton}>
            {/* <Tooltip
              id="linkedin-tooltip"
              title="Follow us on LinkedIn"
              placement={window.innerWidth > 959 ? "top" : "left"}
              classes={{ tooltip: classes.tooltip }}
            > */}
              <Button
                size="sm"
                style={{backgroundColor: '#000080', color: 'white', marginLeft: '5px', marginTop: '5px'}}
                //color="#000080"
                href="https://app.pureblue.ai/"
                target="_blank"
                // className={classes.navLink}
                onClick={props.handleDrawerToggle}
              >Login  
                {/* <i className={classes.socialIcons + " fab fa-linkedin"} /> */}
              </Button>
            {/* </Tooltip> */}
          </ListItem>
    </List>
  );
}
