import React, {useEffect} from 'react';
import { Card } from '@material-ui/core';   //Container, 
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
const useStyles = makeStyles(styles);

const HubspotForm = () => {

  const classes = useStyles();

  useEffect(()=>{
    const script = document.createElement("script");
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      if(window.hbspt) {
        window.hbspt.forms.create({ 
          region: "na1",
          target: '#hubspotForm',
          portalId: "7844857",
          formId: "b5261c9a-47b9-4fde-8a9a-679fc54707e3"
        });
      }    
    });
  },[]);
  
  return (

    <div id="moreinfo" className={classes.sections}>
      <Card className={classes.container} style={{padding: 20, borderWidth: 'thick thick thick thick'}}>
      {/* <Container style={{ padding: 20 }}> */}
        <h1 style={{fontFamily: 'Roboto', textAlign: 'center'}}><strong>For More Info</strong></h1>
        <div style={{ padding: 20 }}>
        <div id="hubspotForm"></div>
        </div>
      {/* </Container> */}
      </Card>
    </div>
  );
}

export default HubspotForm;
