import React from "react";

import Background from "./Background.js"

import corner1 from 'assets/img/illustrations/corner-1.png';
import corner2 from 'assets/img/illustrations/corner-2.png';
import corner3 from 'assets/img/illustrations/corner-3.png';

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import People from "@material-ui/icons/People";
import Check from "@material-ui/icons/Check";
import Card from "@material-ui/core/Card"
//import {Card, CardHeader, CardBody} from "reactstrap";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import "./backgroundCard.css";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

const getImage = num => {
    switch (num) {
      case '1':
        return corner1;
      case '2':
        return corner2;
      case '3':
        return corner3;
      default:
        return corner1;
    }
};

const BackgroundCard = (props) => {
  const classes = useStyles();
  const Icon = props.icon ? props.icon : <Check />;
    return (
      <Card className={classes.container} style={{padding: 25, margin: 0, marginRight: 25}}>
          <Background image={props.num ? getImage(props.num) : corner1} className="bg-card" />
          {props.icon}
          <h3>{props.text}</h3> 
      </Card>
    )
}

export default BackgroundCard;