/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
// plugin that creates slider
import Slider from "nouislider";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import Switch from "@material-ui/core/Switch";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import People from "@material-ui/icons/People";
import Check from "@material-ui/icons/Check";
import Card from "@material-ui/core/Card"
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
// import Paginations from "components/Pagination/Pagination.js";
// import Badge from "components/Badge/Badge.js";

import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import AttachMoney from '@material-ui/icons/AttachMoney';
import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
//import pureblueLogo from "assets/img/pureblueLogoLarge.png";
import pureblueLogo from "assets/img/PureBlue.aiV2.png"

const useStyles = makeStyles(styles);

export default function SectionIntro() {
  const classes = useStyles();
  // const [checked, setChecked] = React.useState([24, 22]);
  // const [selectedEnabled, setSelectedEnabled] = React.useState("b");
  // const [checkedA, setCheckedA] = React.useState(true);
  // const [checkedB, setCheckedB] = React.useState(false);

//   React.useEffect(() => {
//     if (
//       !document
//         .getElementById("sliderRegular")
//         .classList.contains("noUi-target")
//     ) {
//       Slider.create(document.getElementById("sliderRegular"), {
//         start: [40],
//         connect: [true, false],
//         step: 1,
//         range: { min: 0, max: 100 }
//       });
//     }
//     if (
//       !document.getElementById("sliderDouble").classList.contains("noUi-target")
//     ) {
//       Slider.create(document.getElementById("sliderDouble"), {
//         start: [20, 60],
//         connect: [false, true, false],
//         step: 1,
//         range: { min: 0, max: 100 }
//       });
//     }
//     return function cleanup() {};
//   });
//   const handleToggle = value => {
//     const currentIndex = checked.indexOf(value);
//     const newChecked = [...checked];

//     if (currentIndex === -1) {
//       newChecked.push(value);
//     } else {
//       newChecked.splice(currentIndex, 1);
//     }
//     setChecked(newChecked);
//   };

  const [largeLogo, setLargeLogo] = React.useState(true);

  useEffect(()=>{
    if (window.innerWidth > 992)
      setLargeLogo(true);
    else
    setLargeLogo(false);
  }, [window.innerWidth])

  window.onresize = ()=>{
    if (window.innerWidth > 992)
      setLargeLogo(true);
    else
    setLargeLogo(false);
  };

  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        {/* <div>
          <span>
            <h2>
            {largeLogo ? 
            <img src={pureblueLogo} alt='PureBlue. Find your bluebirds with ai'></img>
            : 
            <img src={pureblueLogo} width="252" height="100" alt='PureBlue - Find your bluebirds'></img>
            }
            </h2>
          </span>
        </div> */}
        {/* <Card style={{padding: 20}}> */}
          <h1 style={{fontFamily: 'Roboto', textAlign: 'center'}}><strong>What is PureBlue?</strong></h1>
          <h3 style={{fontFamily: 'Roboto'}}><b><span style={{color: '#000080'}}>PureBlue</span></b> (formerly aiLEAD) is a SaaS 
          offering that uses machine learning and CRM data to find the best B2B leads, dramatically improving your conversion rate.  PureBlue 
          continues to learn using the feedback from our recommendations, continuing to increase deal flow with less effort by your sales 
          organization.  PureBlue finds the right “haystack” and makes it smaller in order to make finding “needles” much easier.</h3>
        {/* </Card> */}
      </div>
    </div>
  );
}
