import React from "react";
import Card from "@material-ui/core/Card"
import { makeStyles } from "@material-ui/core/styles";
import {Container, Row, Col} from 'reactstrap';
import { //People, 
  PostAdd, Email as AlternateEmail, 
  Home, Info, Phone as ContactPhone //PeopleAltRounded 
} from "@material-ui/icons";

import map from '../../../assets/img/map.svg';
import phone from '../../../assets/img/phone.svg';
import email from '../../../assets/img/email.svg';
import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
import "../../../assets/css/aiBullet.css";

const useStyles = makeStyles(styles);

export default function SectionContact() {
  const classes = useStyles();

  return (
    <div id="contact" className={classes.sections}>
      <div className={classes.container}>

      {/* <Card className={classes.container} style={{width: '100%', padding: 20}}> */}
        <div>
          <h1 style={{fontFamily: 'Roboto', textAlign: 'center'}}><strong>Contact Us</strong></h1>
        </div>
        <Container className={classes.card} style={{width: '100%'}}>
          <Row>
            <Col  style={{display: 'inline', paddingRight: '2'}} sm="1">
                  <img src={map} alt='Mailing address for Pure Blue' />&nbsp;&nbsp;
            </Col>
            <Col style={{display: 'inline'}} sm="11">
                <span style={{fontSize: '1.1rem', fontFamily: 'Roboto'}}><b>aiLEAD, Inc. dba <span style={{color: '#000080'}}>PureBlue.ai</span></b><br />
                3571 Far West Blvd, Suite 3103<br />
                Austin, TX 78731
                </span><p>&nbsp;</p>
            </Col>
          </Row>
          <Row>
            <Col style={{display: 'inline', marginRight: '2'}} sm="1">
                  <img src={phone} alt='Phone number for Pure Blue' />&nbsp;&nbsp;
            </Col>
            <Col style={{display: 'inline'}} sm="11">
                <span style={{fontSize: '1.1rem', fontFamily: 'Roboto'}}><b>+1 (512) 763-0906</b></span>
            </Col>
          </Row>
          <Row>
          <Col style={{display: 'inline', paddingRight: '2'}} sm="1">
                  <img src={email} alt='Email address for Pure Blue' />&nbsp;&nbsp;
            </Col>
            <Col style={{display: 'inline'}} sm="11">
                <span style={{fontSize: '1.1rem', fontFamily: 'Roboto'}}><b><a href="mailto:sales@pureblue.ai">sales@pureblue.ai</a></b></span>
            </Col>
          </Row>
        </Container>
      {/* </Card> */}
      </div>
    </div>
  );
}
