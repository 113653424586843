import React from "react";
import Card from "@material-ui/core/Card"
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
const useStyles = makeStyles(styles);

export default function SectionPricing() {
  const classes = useStyles();

  return (
    <div id="pricing" className={classes.sections}>
      <div className={classes.container}>
      {/* <Card className={classes.container} style={{padding: 20}}> */}
        <div>
          <h1 style={{fontFamily: 'Roboto', textAlign: 'center'}}><strong>Pricing</strong></h1>
          <h3 style={{fontFamily: 'Roboto'}}><span style={{color: '#000080'}}><b>PureBlue</b></span> fills your funnel faster and puts you in front of the right prospects every day.&nbsp;
            Contact us below for pricing or to set up a quick demo.</h3>
        </div>
      {/* </Card> */}
     </div>
    </div>
  );
}
