import React, {useState, useEffect} from "react";
//import Card from "@material-ui/core/Card"
import Parallax from "../../../components/Parallax/Parallax.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
import "../../../assets/css/aiBullet.css";

const useStyles = makeStyles(styles);

export default function SectionImageSeparator() {
  const classes = useStyles();
  const [largeImage, setLargeImage] = useState(true);
  const [ratio, setRatio] = useState(1.00);

  useEffect(()=>{
    if (window.innerWidth > 768)
      setLargeImage(true);
    else {
      setLargeImage(false);
    }
  }, [window.innerWidth])

  window.onresize = ()=>{
    setRatio(window.innerWidth / 1920);
    if (window.innerWidth > 768)
      setLargeImage(true);
    else {
      setLargeImage(false);
    }
  };

  const smallwidth = 1920 / 2;
  const smallheight = smallwidth / 4.47;

  return (
    
    <div className={classes.sections} style={{margin: '20'}}>
      {/* <Parallax image={require("assets/img/AustinDiagonal.png")} /> */}
      {/* {largeImage ?
        <img src={require("assets/img/AustinDiagonal.png")} alt="Austin Skyline section separator"></img> */}
      {/* :  */}
      <img src={require("assets/img/AustinDiagonal.png")} alt="Austin Skyline section separator"
        //width={1920 * ratio} height={432 * ratio}
        width={window.innerWidth} height={window.innerWidth / 4.47}
        >
        </img>
      {/* } */}
    </div>
  );
}
