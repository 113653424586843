import React from "react";
import Card from "@material-ui/core/Card"
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
import "../../../assets/css/aiBullet.css";

const useStyles = makeStyles(styles);

export default function SectionWhyPureBlue() {
  const classes = useStyles();


  return (
    <div id="whypureblue" className={classes.sections}>
      <div className={classes.container}>
        <div>
          <h1 style={{fontFamily: 'Roboto', textAlign: 'center', marginBottom: '20'}}><strong>Why PureBlue?</strong></h1>
        </div>
          {/* <div className={classes.card}> */}
            {/* <ul className="aiBullet">  
                <li style={{fontSize: '1.1rem', fontFamily: 'Roboto'}}>To Drive more revenue with sales and lead development resources</li>
                <li style={{fontSize: '1.1rem', fontFamily: 'Roboto'}}>To Stop wasting time cold-calling and cold-emailing too many prospects</li>
                <li style={{fontSize: '1.1rem', fontFamily: 'Roboto'}}>To Build a Customized Sales Learning Engine</li>
            </ul> */}
            <GridContainer>
                <GridItem xs={12} sm={6} md={4} lg={4} style={{fontSize: '1.1rem', fontFamily: 'Roboto'}}>
                  <img className={classes.centerImage} src={require("assets/img/whypureblue3.svg")}></img>
                  <h3>Drive more revenue with sales and lead development</h3>
                </GridItem>
                <GridItem xs={12} sm={6} md={4} lg={4} style={{fontSize: '1.1rem', fontFamily: 'Roboto'}}>
                  <img className={classes.centerImage} src={require("assets/img/whypureblue2.svg")}></img>
                  <h3>Leverage your time by calling and emailing more focused prospects</h3>
                </GridItem>
                <GridItem xs={12} sm={6} md={4} lg={4} style={{fontSize: '1.1rem', fontFamily: 'Roboto'}}>
                  <img className={classes.centerImage} src={require("assets/img/whypureblue1.svg")}></img>
                  <h3>Build a Customized Sales Learning Engine</h3>
                </GridItem>
            </GridContainer>
          {/* </div> */}
      </div>
    </div>
  );
}
