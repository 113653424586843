/* eslint-disable no-unused-vars */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import Email from "@material-ui/icons/Email";
// import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
//import SectionNavbars from "./Sections/SectionNavbars.js";
import SectionNotifications from "./Sections/SectionNotifications";
import logo from "../../assets/img/PureBlueLogoWhiteBG.svg"
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionIntro from "./Sections/SectionIntro.js";
import SectionWhyPureBlue from "./Sections/SectionWhyPureBlue.js";
import SectionImageSeparator from "./Sections/SectionImageSeparator.js";
import SectionHowItWorks from "./Sections/SectionHowItWorks.js"
import SectionPricing from "./Sections/SectionPricing.jsx";
import SectionContact from "./Sections/SectionContact.jsx";
import HubspotForm from "./Sections/SectionMoreInfo.jsx";

import styles from "assets/jss/material-kit-react/views/components.js";
// import profileImage from "assets/img/faces/avatar.jpg";

const useStyles = makeStyles(styles);

export default function Components(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(prev=> !prev);
  };
  
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div id="top">

      <Header
        //brand="PureBlue"
        brand=""
        mobileOpen={mobileOpen}
        setMobileOpen={setMobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        rightLinks={<HeaderLinks 
            handleDrawerToggle={handleDrawerToggle}
        />}
        fixed
        color="transparent"
        //color="default"
        //color={'rgba(51, 51, 51, 0.6)'}
        //color="#333333"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />

{/* background-color: #000000;
background-image: linear-gradient(147deg, #000000 0%, #04619f 74%); */}
{/* background-color: #4834d4;
background-image: linear-gradient(315deg, #4834d4 0%, #0c0c0c 74%); */}
      <Parallax image={require("assets/img/AustinSkyline.png")}>
      {/* <div style={{height: '300px', backgroundColor: "#2a2a72", backgroundImage: `linear-gradient(147deg, #000000 0%, #04619f 74%)`}}> */}
      {/* <div style={{height: '220px', backgroundColor: "#363eab", backgroundImage: `linear-gradient(315deg, #0c0c0c 0%, #374cb4 74%)`}}> */}
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title} style={{fontFamily: 'Roboto'}}>Introducing PureBlue</h1>
                <h1 className={classes.subtitle} style={{fontFamily: 'Roboto'}}><b>
                  Find Your Bluebirds With AI
                </b></h1>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        {/* </div> */}
      </Parallax>
      <div className={classNames(classes.main)}>
      {/* , classes.mainRaised */}
        <SectionIntro />
        <SectionWhyPureBlue id="whypureblue" />
        <SectionImageSeparator />
        <SectionHowItWorks />
        <SectionPricing />
        <SectionContact />
        <HubspotForm />
        {/* <SectionPills />
        <SectionNotifications />
        <SectionTypography />
        <SectionJavascript />
        <SectionCarousel />
        <SectionCompletedExamples /> */}
        {/* <SectionLogin />
        <GridItem md={12} className={classes.textCenter}>
          <Link to={"/login-page"} className={classes.link}>
            <Button color="primary" size="lg" simple>
              View Login Page
            </Button>
          </Link>
        </GridItem>
        <SectionExamples />
        <SectionDownload /> */}
      </div>
      <Footer whiteFont="text-primary"/>
    </div>
  );
}
